import { useEffect, useState } from "react";
import { DefMissionDetail, MissionDetail } from "../../types";
import { getCookie, getTranslations } from "../../utils/helpers";

import "./missions.style.css";
import { getMissionResult } from "./utils";

export const MissionsScreen = () => {
  const query = new URLSearchParams(window.location.search);
  const mission = query.get("mission") || "";
  const team = getCookie("team");
  const session = getCookie("session");
  const [missionDetail, setMissionDetail] =
    useState<MissionDetail>(DefMissionDetail);
  const [duplicate, setduplicate] = useState<boolean>(false);
  const [empty, setempty] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(true);
  const [translations, settranslations] = useState<{ [key: string]: string }>(
    {}
  );

  useEffect(() => {
    (async () => {
      const detail = await getMissionResult(team, mission, session);
      if (detail === "Already scanned") {
        setduplicate(true);
      } else if (!detail) {
        setempty(true);
      } else {
        setMissionDetail(detail);
      }
      await getTranslations(settranslations, {
        session,
        keys: ["duplicate_mission", "invalid_mission", "mission_complete"],
      });
      setloading(false);
    })();
  }, [team, mission, session]);

  const generateRapport = (missionDetail: MissionDetail): any => {
    const DOM: any[] = [];
    const {
      passion_description,
      people_description,
      positivity_description,
      progress_description,
      purpose_description,
    } = missionDetail;
    if (passion_description !== "") {
      DOM.push(
        <div className="mission-description">
          <span className="p-title">Passion: </span>
          <span>{passion_description}</span>
        </div>
      );
    }
    if (people_description !== "") {
      DOM.push(
        <div className="mission-description">
          <span className="p-title">People: </span>
          <span>{people_description}</span>
        </div>
      );
    }
    if (positivity_description !== "") {
      DOM.push(
        <div className="mission-description">
          <span className="p-title">Positivity: </span>
          <span>{positivity_description}</span>
        </div>
      );
    }
    if (progress_description !== "") {
      DOM.push(
        <div className="mission-description">
          <span className="p-title">Progress: </span>
          <span>{progress_description}</span>
        </div>
      );
    }
    if (purpose_description !== "") {
      DOM.push(
        <div className="mission-description">
          <span className="p-title">Purpose: </span>
          <span>{purpose_description}</span>
        </div>
      );
    }

    return DOM;
  };
  return (
    <div className="missions-container">
      {empty ? (
        <div>
          <h2>{translations.invalid_mission}</h2>
        </div>
      ) : (
        <></>
      )}
      {duplicate ? (
        <div>
          <h2>{translations.duplicate_mission}</h2>
        </div>
      ) : (
        <></>
      )}

      {!empty && !duplicate && !loading ? (
        <>
          <h2>{translations.mission_complete}</h2>
          <div className="descriptions-container">
            {generateRapport(missionDetail)}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
