import QRCode from "react-qr-code"
import { config } from "../../../config/config"

export const NewsletterCodeComponent = ()=>{
    const session = window.location.href.split('/')[4]
    return (
        <div style={{backgroundColor:"#40826D",height:"100vh",display:'flex',justifyContent:'center','alignItems':'center'}}>
            <QRCode
              className="qr"
              value={`${config.base_url}/newsletter/${session}`}
            />
        </div>
    )
}