import React, { useEffect, useState } from "react";
import "./maps.style.css";

export const MapComponent: React.FC<{ data: number[] }> = (props) => {
  const { data } = props;
  const [pieces, setpieces] = useState<any[]>([]);

  useEffect(() => {
    const _pieces = [];
    for (const id of data) {
      _pieces.push(
        <img
          className="map-piece"
          key={id}
          src={require(`./assets/${id}.png`)}
          alt=""
        />
      );
    }
    setpieces(_pieces);
  }, [data]);
  return (
    <div className="map-container">
      <img className="base-map" src={require("./assets/basemap.jpeg")} alt="" />
      {pieces}
    </div>
  );
};
