import { useEffect, useState } from "react";
import {
  CreateNewFacilitator,
  DeleteFacilitator,
  getFacilitators,
  showMonthlyStats,
  UpdateFacilitators,
} from "../../../utils/helpers";
import "./admin-facilitators.style.css";
const defFacilitator = {
  firstname: "",
  lastname: "",
  email: "",
};
export const AdminFacilitators = () => {
  const [facilitators, setfacilitators] = useState<any[]>([]);
  const [newfac, setnewfac] = useState<boolean>(false);
  const [edituuid, setedituuid] = useState<string>("");
  const [newfacilitator, setnewfacilitator] = useState<{
    firstname: string;
    lastname: string;
    email: string;
  }>(defFacilitator);

  useEffect(() => {
    getFacilitators(setfacilitators);
  }, []);
  return (
    <div className="facilitator-container">
      <div className="sub-header">
        <div className="sub-header-content">
          <button
            onClick={() => {
              setnewfac(true);
            }}
          >
            New Facilitator
          </button>
        </div>
      </div>
      <div className="facilitator-record record-header">
        <div className="facilitator-key">
          <span>Firstname</span>
        </div>
        <div className="facilitator-key">
          <span>Lastname</span>
        </div>
        <div className="facilitator-key">
          <span>Email</span>
        </div>
        <div className="facilitator-edit">
          <span></span>
        </div>
      </div>
      {newfac ? (
        <form className="facilitator-record new-record">
          <div className="facilitator-key">
            <input
              type="text"
              name="firstname"
              value={newfacilitator.firstname}
              required
              onChange={(event) => {
                setnewfacilitator({
                  ...newfacilitator,
                  firstname: event.target.value,
                });
              }}
              placeholder="firstname"
            />
          </div>
          <div className="facilitator-key">
            <input
              type="text"
              name="lastname"
              value={newfacilitator.lastname}
              placeholder="lastname"
              required
              onChange={(event) => {
                setnewfacilitator({
                  ...newfacilitator,
                  lastname: event.target.value,
                });
              }}
            />
          </div>
          <div className="facilitator-key">
            <input
              type="email"
              name="email"
              value={newfacilitator.email}
              required
              placeholder="email"
              onChange={(event) => {
                setnewfacilitator({
                  ...newfacilitator,
                  email: event.target.value,
                });
              }}
            />
          </div>
          <div className="facilitator-edit">
            <button
              type="submit"
              onClick={async () => {
                setnewfac(false);
                await CreateNewFacilitator(newfacilitator);
                setnewfacilitator(defFacilitator);
                await getFacilitators(setfacilitators);
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                setnewfac(false);
                setnewfacilitator(defFacilitator);
              }}
            >
              Close
            </button>
          </div>
        </form>
      ) : (
        <></>
      )}

      {facilitators.map((f: any) => {
        return (
          <div
            className="facilitator-record"
            onClick={() => {
              showMonthlyStats(f);
            }}
          >
            <div className="facilitator-key">
              {edituuid === f.uuid ? (
                <input
                onClick={(event)=>{
                  event.stopPropagation()
                }}
                  type="text"
                  name=""
                  id=""
                  value={f.firstname}
                  onChange={(event: any) => {
                    setfacilitators((current: any) => {
                      return current.map((_f: any) => {
                        if (_f.uuid === f.uuid) {
                          _f.firstname = event.target.value;
                        }
                        return _f;
                      });
                    });
                  }}
                />
              ) : (
                <span>{f.firstname}</span>
              )}
            </div>
            <div className="facilitator-key">
              {edituuid === f.uuid ? (
                <input
                onClick={(event)=>{
                  event.stopPropagation()
                }}
                  type="text"
                  name=""
                  id=""
                  value={f.lastname}
                  onChange={(event: any) => {
                    setfacilitators((current: any) => {
                      return current.map((_f: any) => {
                        if (_f.uuid === f.uuid) {
                          _f.lastname = event.target.value;
                        }
                        return _f;
                      });
                    });
                  }}
                />
              ) : (
                <span>{f.lastname}</span>
              )}
            </div>
            <div className="facilitator-key">
              {edituuid === f.uuid ? (
                <input
                onClick={(event)=>{
                  event.stopPropagation()
                }}
                  type="text"
                  name=""
                  id=""
                  value={f.email}
                  onChange={(event: any) => {
                    setfacilitators((current: any) => {
                      return current.map((_f: any) => {
                        if (_f.uuid === f.uuid) {
                          _f.email = event.target.value;
                        }
                        return _f;
                      });
                    });
                  }}
                />
              ) : (
                <span>{f.email}</span>
              )}
            </div>
            <div className="facilitator-edit">
              {edituuid === f.uuid ? (
                <button
                  onClick={async (event:any) => {
                    event.stopPropagation()
                    setedituuid("");
                    await UpdateFacilitators(facilitators);
                    await getFacilitators(setfacilitators);
                  }}
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={(event:any) => {
                    event.stopPropagation()
                    setedituuid(f.uuid);
                  }}
                >
                  Edit
                </button>
              )}
              <button
                onClick={async (event:any) => {
                  event.stopPropagation()
                  await DeleteFacilitator(f.uuid);
                  await getFacilitators(setfacilitators);
                }}
              >
                Delete
              </button>
            </div>

            <span></span>
          </div>
        );
      })}
    </div>
  );
};
