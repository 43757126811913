import { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./stats.style.css";
import { barChartData, barChartOptions, getTeamStats } from "./utils";
import { MapComponent } from "../../components/maps/maps.component";
import { Mission } from "../../components/mission/mission.component";
import {
  doesSessionExist,
  getTranslations,
  isSessionOpen,
} from "../../utils/helpers";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const StatsScreen = () => {
  const session = window.location.pathname.split("/")[2];
  const [minutes, setminutes] = useState<string>("08");
  const [seconds, setseconds] = useState<number>(480);
  const [translations, settranslations] = useState<{ [key: string]: string }>(
    {}
  );
  const [sessionStatus, setsessionStatus] = useState<{
    status: string;
    start_date: Date;
  }>({ status: "", start_date: new Date() });

  const [team1data, setteam1data] = useState<{
    data: any;
    missions: any[];
    achieved: number[];
  }>({ data: {}, missions: [], achieved: [] });
  const [team2data, setteam2data] = useState<{
    data: any;
    missions: any[];
    achieved: number[];
  }>({ data: {}, missions: [], achieved: [] });

  const getData = async () => {
    doesSessionExist(session);
    setteam1data(await getTeamStats("1", session));
    setteam2data(await getTeamStats("2", session));
  };
  const generateMissions = (missionObj: any[]) => {
    const missions = [];
    for (const mis of missionObj) {
      missions.push(<Mission name={`${mis.island} ${mis.mission}`} />);
    }
    return missions;
  };
  const changeTime = (min: string) => {
    setminutes(min);
    setseconds(parseInt(min) * 60);
  };
  useEffect(() => {
    isSessionOpen(session, setsessionStatus);
    getData();
    getTranslations(settranslations, {
      session,
      keys: ["completed_missions", "team", "session_start", "at"],
    });

    const interval = setInterval(() => getData(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      {sessionStatus.status !== "active" ? (
        <div className="stats-container">
          <div className="stats-outer-container">
            <div className="stats-team-name top-left-side">
              <p>{translations.team} 1</p>
            </div>
            <div className="stats-chart-container left-side">
              <Bar
                className="chart"
                options={barChartOptions(`${translations.team} 1`)}
                data={barChartData(team1data.data)}
              />
              <div className="stats-chart-numbers">
                <span>{team1data.data.positivity}</span>
                <span>{team1data.data.purpose}</span>
                <span>{team1data.data.people}</span>
                <span>{team1data.data.passion}</span>
                <span>{team1data.data.progress}</span>
              </div>
            </div>
            <div className="stats-map-container left-side">
              <MapComponent data={team1data.achieved} />
            </div>
          </div>

          <div className="stats-middle-container">
            <div className="clock-container top-left-side top-right-side">
              <div className="timer-buttons">
                <button
                  onClick={() => {
                    changeTime("08");
                  }}
                >
                  8 min
                </button>
                <button
                  onClick={() => {
                    changeTime("10");
                  }}
                >
                  10 min
                </button>
                <button
                  onClick={() => {
                    changeTime("15");
                  }}
                >
                  15 min
                </button>
              </div>
              <iframe
                title="countdown"
                className="iframe"
                frameBorder={0}
                src={`https://vclock.com/embed/timer/#countdown=00:${minutes}:00&enabled=0&seconds=${seconds}&theme=0&ampm=1&sound=school`}
              ></iframe>
            </div>

            <div className="stats-missions-container ">
              <div className="stats-mission-container left-side right-side">
                <p>
                  {translations.completed_missions}: {team1data.missions.length}
                </p>

                <div className="stats-missions">
                  {generateMissions(team1data.missions)}
                </div>
              </div>
              <div className="stats-mission-container left-side right-side">
                <p>
                  {translations.completed_missions}: {team2data.missions.length}
                </p>
                <div className="stats-missions">
                  {generateMissions(team2data.missions)}
                </div>
              </div>
            </div>
          </div>

          <div className="stats-outer-container">
            <div className="stats-team-name top-right-side ">
              <p>{translations.team} 2</p>
            </div>
            <div className="stats-chart-container right-side">
              <Bar
                className="chart"
                options={barChartOptions(`${translations.team} 2`)}
                data={barChartData(team2data.data)}
              />
              <div className="stats-chart-numbers">
                <span>{team2data.data.positivity}</span>
                <span>{team2data.data.purpose}</span>
                <span>{team2data.data.people}</span>
                <span>{team2data.data.passion}</span>
                <span>{team2data.data.progress}</span>
              </div>
            </div>
            <div className="stats-map-container right-side">
              <MapComponent data={team2data.achieved} />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="stats-container"
          style={{
            color: "white",
            fontSize: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {translations.session_start}{" "}
          {moment(sessionStatus.start_date).format("DD-MM-YY")}{" "}
          {translations.at} 7:45
        </div>
      )}
    </>
  );
};
