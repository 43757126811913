export type MissionDetail = {
  positivity_description: string;
  purpose_description: string;
  people_description: string;
  passion_description: string;
  progress_description: string;
};
export const DefMissionDetail = {
  positivity_description: "",
  purpose_description: "",
  people_description: "",
  passion_description: "",
  progress_description: "",
};
export const DefMission = {
  mission: "",
  island: "",
  mission_answer: "",
  positivity: 0,
  passion: 0,
  people: 0,
  progress: 0,
  purpose: 0,
};

export type Mission = {
  uuid?: string;
  mission: string;
  island: string;
  mission_answer: string;
  description: {
    [key: string]: {
      positivity_description?: string;
      passion_description?: string;
      people_description?: string;
      progress_description?: string;
      purpose_description?: string;
    };
  };
  positivity: number;
  passion: number;
  people: number;
  progress: number;
  purpose: number;
};
