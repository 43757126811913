import axios from "axios";
import { config } from "../config/config";
import moment from "moment";

export function getCookie(cookieName: string) {
  let cookie: any = {};
  document.cookie.split(";").forEach(function (el) {
    let [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
}
export const doesSessionExist = async (session_code: string, setter?: any) => {
  const check = await (
    await axios.get(`${config.api_url}/sessions/check/${session_code}`)
  ).data;
  if (setter) {
    setter(check);
  }
  if (!check) {
    window.location.href = "/";
  }
};
export const getLanguages = async (setlanguages: any) => {
  await setlanguages(
    await (
      await axios.get(`${config.api_url}/languages`)
    ).data
  );
};

export const addNewLanguage = async (language: any) => {
  await axios.post(`${config.api_url}/languages`, { language });
};

export const getTranslations = async (
  setter: any,
  translations: {
    session: string;
    keys: string[];
  }
) => {
  setter(
    (
      await axios.post(`${config.api_url}/translations`, {
        translations,
      })
    ).data
  );
};

export const getFacilitators = async (setter: any) => {
  await setter((await axios.get(`${config.api_url}/facilitators`)).data);
};

export const CreateNewFacilitator = async (facilitator: any) => {
  await axios.post(`${config.api_url}/facilitators`, { facilitator });
};
export const UpdateFacilitators = async (facilitators: any[]) => {
  await axios.put(`${config.api_url}/facilitators`, { facilitators });
};
export const DeleteFacilitator = async (uuid: string) => {
  await axios.delete(`${config.api_url}/facilitators/${uuid}`);
};
export const sendFacilitatorEmail = async (session: any) => {
  await axios.post(`${config.api_url}/facilitators/email`, { session });
};
export const isSessionOpen = async (session_code: string, setter: any) => {
  setter(
    (await axios.get(`${config.api_url}/sessions/live/${session_code}`)).data
  );
};

export const showMonthlyStats = (f: any) => {
  window.alert(
    `Gefaciliteerde Sessies: \n 
    ${moment().format("MMMM")}: ${f.stats[0]} game(s) \n
    ${moment().subtract(1, "M").format("MMMM")}: ${f.stats["-1"]} game(s) \n
    ${moment().subtract(2, "M").format("MMMM")}: ${f.stats["-2"]} game(s) \n
  `
  );
};
