import axios from "axios";
import "./admin-codes.style.css";
import { useEffect, useState } from "react";
import { config } from "../../../config/config";
import QRCode from "react-qr-code";
import React from "react";
import ReactToPrint from "react-to-print";
export const AdminCodes = () => {
  const [missions, setmissions] = useState<any[]>([]);
  let printRef = React.useRef() as any;
  const getMissions = async () => {
    setmissions((await axios.get(`${config.api_url}/missions/all`)).data);
  };

  useEffect(() => {
    (async () => {
      await getMissions();
    })();
  }, []);

  const generateCodes = () => {
    const codes: any[] = [];
    for (const { mission, island, mission_answer } of missions) {
      codes.push(
        <div className="qr-code-container">
          <div className="qr-code">
            <QRCode
              value={`${config.base_url}/missions?mission=${island}_${mission}.${mission_answer}`}
            />
          </div>
          <span>{`${island}: ${mission}.${mission_answer}`}</span>
        </div>
      );
    }

    return codes;
  };

  return (
    <div className="admin-codes-container">
      <div className="sub-header">
        <div className="sub-header-content">
          <ReactToPrint
            trigger={() => <button>Print</button>}
            content={() => printRef}
          />
        </div>
      </div>
      <div ref={(el) => (printRef = el)} className="qr-codes-container">
        {generateCodes()}
      </div>
    </div>
  );
};
