const getConfig = (key:string)=>{
    const base_url = window.location.host;
    switch (key) {
        case 'api_url':
            if(base_url === 'localhost:3000') return 'http://localhost:8080'
            else if(base_url === 'happy-horizon.dev.umango.dev') return 'https://happy-horizon-api.dev.umango.dev'
            else if(base_url === 'happy-horizon.umango.app') return 'https://happy-horizon-api.umango.app'
            break;
        
        case 'base_url':
            return window.location.origin
        default:
            break;
    }

}


export const config = {
    api_url: getConfig('api_url'),
    base_url: getConfig('base_url')
}

