import axios from 'axios'
import { useEffect, useState } from 'react'
import { config } from '../../config/config'
import { getTranslations } from '../../utils/helpers'
import './newsletter.style.css'

export const NewsletterScreen = ()=>{
    const session = window.location.href.split('/')[4]
    const [email,setemail] = useState<string>("")
    const [sent,setsent] = useState<boolean>(false);
    const [translations, settranslations] = useState<{ [key: string]: string }>(
        {}
      );
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const sendNewsletter = async() =>{
       await axios.post(`${config.api_url}/sessions/newsletter/${session}`,{email})
        setsent(true);
    }
    useEffect(() => {
        getTranslations(settranslations, {
            session,
            keys: ["newsletter_thanks"],
          });
    
     
    }, [])
    
    return (
        <div  className='newsletter-container'>

            {sent?
            <p>{translations.newsletter_thanks}</p>:
            <div className='newsletter-form'>
                <input type="email" name="email" required  placeholder="Email..." value={email} onChange={(event)=>{setemail(event.target.value)}}/>
                <button disabled={!regex.test(email)} className={!regex.test(email)?'disabled':''} onClick={()=>{sendNewsletter()}}>Receive scores</button>
            </div>}
        </div>
    )
}