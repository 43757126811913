import { AdminCodes } from "../../components/admin/codes/admin-codes.component";
import { AdminFacilitators } from "../../components/admin/facilitators/admin-facilitators.component";
import { AdminLanguages } from "../../components/admin/languages/admin-languages.component";
import { AdminMissions } from "../../components/admin/missions/admin-missions.component";
import { AdminSessions } from "../../components/admin/sessions/admin-sessions.component";
import "./admin.style.css";

export const AdminScreen = () => {
  const path = window.location.pathname.split("/")[2];

  return (
    <div className="admin-container">
      <div className="selector-buttons">
        {path !== "sessions" ? (
          <span
            onClick={() => {
              window.location.href = "/admin/sessions";
            }}
          >
            {" "}
            Sessions
          </span>
        ) : (
          <></>
        )}
        {path !== "missions" ? (
          <span
            onClick={() => {
              window.location.href = "/admin/missions";
            }}
          >
            {" "}
            Missions
          </span>
        ) : (
          <></>
        )}
        {path !== "codes" ? (
          <span
            onClick={() => {
              window.location.href = "/admin/codes";
            }}
          >
            {" "}
            Generate Codes
          </span>
        ) : (
          <></>
        )}
        {path !== "languages" ? (
          <span
            onClick={() => {
              window.location.href = "/admin/languages";
            }}
          >
            {" "}
            Languages
          </span>
        ) : (
          <></>
        )}
        {path !== "facilitators" ? (
          <span
            onClick={() => {
              window.location.href = "/admin/facilitators";
            }}
          >
            Facilitators
          </span>
        ) : (
          <></>
        )}
      </div>
      <div className="tabs-container">
        {path === "sessions" ? <AdminSessions /> : <></>}
        {path === "missions" ? <AdminMissions /> : <></>}
        {path === "codes" ? <AdminCodes /> : <></>}
        {path === "languages" ? <AdminLanguages /> : <></>}
        {path === "facilitators" ? <AdminFacilitators /> : <></>}
      </div>
    </div>
  );
};
