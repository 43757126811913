import axios from "axios";
import { config } from "../../../config/config";

export const barChartData = (data: {
  positivity: number;
  purpose: number;
  people: number;
  passion: number;
  progress: number;
}) => {
  const { positivity, passion, people, progress, purpose } = data;
  return {
    labels: ["Positivity", "Purpose", "People", "Passion", "Progress"],
    datasets: [
      {
        data: [positivity, purpose, people, passion, progress],
        backgroundColor: [
          "rgba(244, 228, 9, 1)",
          "rgba(115, 210, 222, 1)",
          "rgba(61, 52, 139, 1)",
          "rgba(216, 17, 89, 1)",
          "rgba(72, 191, 132, 1)",
        ],
      },
    ],
  };
};

export const barChartOptions = (team: string) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: true,
        text: `${team}`,
      },
    },
    scales: {
      y: {
        min: -5,
        max: 15,
        stepSize: 1,
      },
    },
  };
};

export const getTeamStats = async (team: string, session: string) => {
  const data = (
    await axios.get(`${config.api_url}/teams/stats/${team}/${session}`)
  ).data;

  const { positivity, passion, progress, purpose, people } = data.team;
  const obj = {
    data: { positivity, passion, progress, purpose, people },
    missions: data.missions,
    achieved: data.achieved,
  };
  return obj;
};
