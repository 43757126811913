import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { CodesScreen } from "./screens/codes/codes.screen";
import { StatsScreen } from "./screens/stats/stats.screen";
import { TeamsScreen } from "./screens/teams/teams.screen";
import { AdminScreen } from "./screens/admin/admin.screen";
import { MissionsScreen } from "./screens/missions/missions.screen";
import { HomeScreen } from "./screens/home/home.screen";
import { NewsletterScreen } from "./screens/newsletter/newsletter.screen";
import { NewsletterCodeComponent } from "./components/newsletter/code/code.component";

function App() {
  const isLoggedIn = true;
  console.log(process.env.NODE_ENV);
  return (
    <div className="App">
      <Routes>
        {isLoggedIn ? (
          <>
            <Route path="/admin/:tab" element={<AdminScreen />} />
          </>
        ) : (
          <></>
        )}
        <Route path="/" element={<HomeScreen />} />
        <Route path="/teamcodes/:session" element={<CodesScreen />} />
        <Route path="/scoreboard/:session" element={<StatsScreen />} />
        <Route path="/teams/:team/:session" element={<TeamsScreen />} />
        <Route path="/missions" element={<MissionsScreen />} />
        <Route path="/newsletter/:session" element={<NewsletterScreen />} />
        <Route path="/newsletter/:session/code" element={<NewsletterCodeComponent />} />
      </Routes>
    </div>
  );
}

export default App;
