import React, { useEffect, useState } from "react";
import { addNewLanguage, getLanguages } from "../../../utils/helpers";
import "./admin-languages.style.css";
import { all } from "langs";

export const AdminLanguages = () => {
  const [languages, setlanguages] = useState<any[]>([]);
  const [alllanguages, setalllanguages] = useState<any[]>([]);
  const [newlang, setnewlang] = useState<boolean>(false);
  const [selectedLanguage, setselectedLanguage] = useState<any>({
    code: "",
    name: "",
  });

  useEffect(() => {
    getLanguages(setlanguages);
    setalllanguages(
      all().map((l: any) => {
        return { code: l[1], name: l.name };
      })
    );
  }, []);

  return (
    <div className="languages-container">
      <div className="sub-header">
        <div className="sub-header-content">
          <button
            onClick={() => {
              setnewlang(true);
              setselectedLanguage(alllanguages[0]);
            }}
          >
            New Language
          </button>
        </div>
      </div>
      {newlang ? (
        <div className="newlanguage language-record">
          <select
            onChange={(event) => {
              setselectedLanguage(JSON.parse(event.target.value));
            }}
            value={JSON.stringify(selectedLanguage)}
          >
            {alllanguages.map((l: any) => {
              return (
                <option value={JSON.stringify(l)} selected>
                  {l.name}
                </option>
              );
            })}
          </select>
          <span>{selectedLanguage.code}</span>
          <button
            onClick={() => {
              setnewlang(false);
              addNewLanguage(selectedLanguage);
            }}
          >
            Save
          </button>
        </div>
      ) : (
        <></>
      )}
      <div className="language-record-header">
        <span>Language</span>
        <span>Code</span>
      </div>
      {languages.map((l: any) => {
        return (
          <div className="language-record">
            <span>{l.language}</span>
            <span>{l.code}</span>
          </div>
        );
      })}
    </div>
  );
};
