import { useEffect, useState } from "react";
import { getTranslations } from "../../utils/helpers";

import "./teams.style.css";

export const TeamsScreen = () => {
  const session = window.location.pathname.split("/")[3];
  const team = window.location.pathname.split("/")[2];
  const [translations, settranslations] = useState<{ [key: string]: string }>(
    {}
  );

  useEffect(() => {
    getTranslations(settranslations, {
      session,
      keys: ["welcome_in", "team"],
    });
    document.cookie = `team=${team};path=/`;
    document.cookie = `session=${session};path=/`;
  }, [team, session]);
  return (
    <div className="teams-container">
      <p>
        {translations.welcome_in} {translations.team} {team}
      </p>
    </div>
  );
};
