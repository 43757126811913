import axios from "axios";
import { useState, useEffect } from "react";
import { config } from "../../../config/config";
import {
  getFacilitators,
  getLanguages,
  sendFacilitatorEmail,
} from "../../../utils/helpers";
import "./admin-sessions.style.css";
import { AiOutlineMail } from "react-icons/ai";

export const AdminSessions = () => {
  const [sessions, setsessions] = useState<any[]>([]);
  const [languages, setlanguages] = useState<any[]>([]);
  const [facilitators, setfacilitators] = useState<any[]>([]);
  const [filter, setfilter] = useState<'all'|'active'|'ongoing'|'finished'>('all');

  const deleteSession = async (session_code: string) => {
    await axios.delete(`${config.api_url}/sessions/${session_code}`);
  };
  const createSession = async () => {
    await axios.post(`${config.api_url}/sessions`);
  };
  const updateSession = async (session: any) => {
    await axios.put(`${config.api_url}/sessions`, { session: session });
  };
  const getSessions = async () => {
    setsessions(await (await axios.get(`${config.api_url}/sessions`)).data);
  };

  useEffect(() => {
    getLanguages(setlanguages);
    getSessions();
    getFacilitators(setfacilitators);
    const interval = setInterval(() => getSessions(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const generateSessions = () => {
    const _sessions: any[] = [];

    for (const sess of sessions.filter((s:any)=>{
      if(filter === 'all'){return s;}
      return s.status === filter
    })) {
      _sessions.push(
        <div className={`session-container `}>
          <div className="session-id">
            <p className="id">{sess.session_code}</p>
            <p className={`status ${sess.status}`}>{sess.status}</p>
          </div>

          <div className="session-actions">
            <div className="session-selectors">
              <input
                disabled={sess.status !== 'active'}
                type="date"
                value={sess.start_date}
                onChange={(event) => {
                  setsessions(
                    sessions.map((a: any) => {
                      if (a.uuid === sess.uuid) {
                        a.start_date = event.target.value;
                      }
                      return a;
                    })
                  );
                  updateSession(sess);
                }}
              />
<select
  disabled={sess.status === 'finished'}
  value={sess.facilitator_uuid}
  onChange={(event) => {
    setsessions(
      sessions.map((a: any) => {
        if (a.facilitator_uuid === sess.facilitator_uuid) {
          a.facilitator_uuid = event.target.value;
        }
        return a;
      })
    );
    updateSession(sess);
  }}
>
  <option value="" disabled>
    Facilitators...
  </option>
  {facilitators.map((f: any) => (
    <option key={f.uuid} value={f.uuid}>
      {f.firstname} {f.lastname}
    </option>
  ))}
</select>
              <select
              disabled={sess.status === 'finished'}
                value={sess.language_code}
                onChange={(event) => {
                  setsessions(
                    sessions.map((a: any) => {
                      if (a.session_code === sess.session_code) {
                        a.language_code = event.target.value;
                      }
                      return a;
                    })
                  );
                  updateSession(sess);
                }}
              >
                {languages.map((l: any) => {
                  return (
                    <option value={l.code}>
                      {l.language} ({l.code})
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="session-buttons">
              <button disabled={sess.status !== 'ongoing'} onClick={() => sendFacilitatorEmail(sess)}>
                Send Email <AiOutlineMail />
              </button>
              <button
                onClick={() =>
                  window.open(`/teamcodes/${sess.session_code}`, "_blank")
                }
              >
                Teams
              </button>
              <button
                onClick={() =>
                  window.open(`/scoreboard/${sess.session_code}`, "_blank")
                }
              >
                Scoreboard
              </button>
              <button
                onClick={() =>
                  window.open(`/newsletter/${sess.session_code}/code`, "_blank")
                }
              >
                Newsletter
              </button>
              <button disabled={sess.status === 'ongoing'} onClick={() => deleteSession(sess.session_code)}>
                Delete
              </button>
            </div>
          </div>
        </div>
      );
    }
    return _sessions;
  };
  return (
    <div>
      <div className="sub-header">
        <div className="sub-header-content">
          <button onClick={createSession}>Create Session</button>
        </div>
      </div>
      <div className="session-filters">
        <span onClick={()=>{setfilter('all')}} className={filter === "all"?'filter-selected':''}>All</span>
        <span onClick={()=>{setfilter('active')}} className={filter === "active"?'filter-selected':''}>Active</span>
        <span onClick={()=>{setfilter('ongoing')}} className={filter === "ongoing"?'filter-selected':''}>Ongoing</span>
        <span onClick={()=>{setfilter('finished')}} className={filter === "finished"?'filter-selected':''}>Finished</span>
      </div>

      <div className="sessions-list-container">{generateSessions()}</div>
    </div>
  );
};
