import axios from "axios";
import { useEffect, useState } from "react";
import { config } from "../../../config/config";
import { DefMission, Mission } from "../../../types";
import "./admin-missions.style.css";
import {
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
} from "react-icons/bs";
import { getLanguages } from "../../../utils/helpers";

export const AdminMissions = () => {
  const [selectedMission, setSelectedMission] = useState({
    name: "",
    lang: "nl",
  });
  const [missions, setMissions] = useState<Mission[]>([]);
  const [search, setsearch] = useState<string>("");
  const [newMission, setnewMission] = useState<Mission | any>();
  const [newMissionlang, setnewMissionlang] = useState<string>("nl");
  const [addNewMission, setaddNewMission] = useState<boolean>(false);
  const [languages, setlanguages] = useState<any[]>([]);

  const deleteMission = async (mission: Mission) => {
    if (
      window.confirm(
        `Are you sure you want to delete ${mission.island}: ${mission.mission}.${mission.mission_answer}?`
      )
    ) {
      const { uuid } = mission;
      await axios.delete(`${config.api_url}/missions/remove/${uuid}`);
      getMissions();
    }
  };
  const getMissions = async () => {
    setSelectedMission({ name: "", lang: "nl" });
    setaddNewMission(false);
    setMissions((await axios.get(`${config.api_url}/missions/all`)).data);
  };
  const addMission = async () => {
    await axios.post(`${config.api_url}/missions/new`, newMission);
    setnewMission(DefMission);
    getMissions();
  };
  const saveMissions = async () => {
    await axios.put(`${config.api_url}/missions/update`, missions);
  };
  const updateMissions = (mis: Mission, key: string, value: any) => {
    setMissions((current: Mission[]) => {
      return current.map((obj: Mission) => {
        if (
          obj.island === mis.island &&
          obj.mission === mis.mission &&
          obj.mission_answer === mis.mission_answer
        ) {
          return {
            ...obj,
            [key]: value,
          };
        }
        return obj;
      });
    });
  };
  const updateDescription = (
    mis: Mission,
    lang: string,
    key: string,
    value: any,
    missionSetter: (any: any) => void
  ) => {
    missionSetter((current: Mission[]) => {
      return current.map((obj: Mission) => {
        if (
          obj.island === mis.island &&
          obj.mission === mis.mission &&
          obj.mission_answer === mis.mission_answer
        ) {
          return {
            ...obj,
            description: {
              ...obj.description,
              [`${lang}`]: { ...obj.description[`${lang}`], [key]: value },
            },
          };
        }
        return obj;
      });
    });
  };
  useEffect(() => {
    getLanguages(setlanguages);
    getMissions();
  }, []);
  const generateAccordeon = () => {
    const _DOM: any[] = [];
    for (const mis of missions.filter((a) => {
      return a.island.toLowerCase().includes(search.toLowerCase());
    })) {
      const {
        island,
        mission_answer,
        mission,
        positivity,
        people,
        purpose,
        progress,
        passion,
      } = mis;
      _DOM.push(
        <div className="mission-record-container">
          <div
            className="mission-record"
            onClick={() => {
              if (
                selectedMission.name ===
                `${island}: ${mission}.${mission_answer}`
              ) {
                setSelectedMission({ ...selectedMission, name: "" });
              } else {
                setSelectedMission({
                  ...selectedMission,
                  name: `${island}: ${mission}.${mission_answer}`,
                });
              }
            }}
          >
            <span className="mission-record-islandname">{`${island}: ${mission}.${mission_answer}`}</span>
            <div className="mission-record-stats">
              <div className="mission-stat">
                <span>Positivity</span>
                {positivity === 0 ? (
                  <span>-</span>
                ) : positivity > 0 ? (
                  <BsFillArrowUpCircleFill color="green" />
                ) : (
                  <BsFillArrowDownCircleFill color="red" />
                )}
              </div>
              <div className="mission-stat">
                <span>People</span>
                {people === 0 ? (
                  <span>-</span>
                ) : people > 0 ? (
                  <BsFillArrowUpCircleFill color="green" />
                ) : (
                  <BsFillArrowDownCircleFill color="red" />
                )}
              </div>
              <div className="mission-stat">
                <span>Progress</span>
                {progress === 0 ? (
                  <span>-</span>
                ) : progress > 0 ? (
                  <BsFillArrowUpCircleFill color="green" />
                ) : (
                  <BsFillArrowDownCircleFill color="red" />
                )}
              </div>
              <div className="mission-stat">
                <span>Purpose</span>
                {purpose === 0 ? (
                  <span>-</span>
                ) : purpose > 0 ? (
                  <BsFillArrowUpCircleFill color="green" />
                ) : (
                  <BsFillArrowDownCircleFill color="red" />
                )}
              </div>
              <div className="mission-stat">
                <span>Passion</span>
                {passion === 0 ? (
                  <span>-</span>
                ) : passion > 0 ? (
                  <BsFillArrowUpCircleFill color="green" />
                ) : (
                  <BsFillArrowDownCircleFill color="red" />
                )}
              </div>
            </div>
          </div>
          {selectedMission.name ===
          `${island}: ${mission}.${mission_answer}` ? (
            <div className="mission-extra-info-container">
              <div className="language-selector">
                {languages.map((k: any) => {
                  return (
                    <span
                      onClick={() => {
                        setSelectedMission({
                          ...selectedMission,
                          lang: k.code,
                        });
                      }}
                      className={
                        selectedMission.lang === k.code
                          ? "selected-language"
                          : ""
                      }
                    >
                      {k.code}
                    </span>
                  );
                })}
              </div>
              <div className="mission-extra-info">
                <div className="mission-col1">
                  <span>Positivity</span>
                  <span>People</span>
                  <span>Progress</span>
                  <span>Purpose</span>
                  <span>Passion</span>
                </div>
                <div className="mission-col2">
                  <input
                    className="value"
                    type="number"
                    id="positivity"
                    value={mis.positivity}
                    onChange={(event) => {
                      updateMissions(
                        mis,
                        "positivity",
                        parseInt(event.target.value)
                      );
                    }}
                  />
                  <input
                    className="value"
                    type="number"
                    id="people"
                    value={mis.people}
                    onChange={(event) => {
                      updateMissions(
                        mis,
                        "people",
                        parseInt(event.target.value)
                      );
                    }}
                  />
                  <input
                    className="value"
                    type="number"
                    id="progress"
                    value={mis.progress}
                    onChange={(event) => {
                      updateMissions(
                        mis,
                        "progress",
                        parseInt(event.target.value)
                      );
                    }}
                  />
                  <input
                    className="value"
                    type="number"
                    id="purpose"
                    value={mis.purpose}
                    onChange={(event) => {
                      updateMissions(
                        mis,
                        "purpose",
                        parseInt(event.target.value)
                      );
                    }}
                  />
                  <input
                    className="value"
                    type="number"
                    id="passion"
                    value={mis.passion}
                    onChange={(event) => {
                      updateMissions(
                        mis,
                        "passion",
                        parseInt(event.target.value)
                      );
                    }}
                  />
                </div>
                <div className="mission-col3">
                  <input
                    className="desc"
                    type="text"
                    id="positivity-desc"
                    value={
                      mis.description[selectedMission.lang]
                        .positivity_description
                    }
                    onChange={(event) => {
                      updateDescription(
                        mis,
                        selectedMission.lang,
                        "positivity_description",
                        event.target.value,
                        setMissions
                      );
                    }}
                  />
                  <input
                    className="desc"
                    type="text"
                    id="people-desc"
                    value={
                      mis.description[selectedMission.lang].people_description
                    }
                    onChange={(event) => {
                      updateDescription(
                        mis,
                        selectedMission.lang,
                        "people_description",
                        event.target.value,
                        setMissions
                      );
                    }}
                  />
                  <input
                    className="desc"
                    type="text"
                    id="progress-desc"
                    value={
                      mis.description[selectedMission.lang].progress_description
                    }
                    onChange={(event) => {
                      updateDescription(
                        mis,
                        selectedMission.lang,
                        "progress_description",
                        event.target.value,
                        setMissions
                      );
                    }}
                  />
                  <input
                    className="desc"
                    type="text"
                    id="purpose-desc"
                    value={
                      mis.description[selectedMission.lang].purpose_description
                    }
                    onChange={(event) => {
                      updateDescription(
                        mis,
                        selectedMission.lang,
                        "purpose_description",
                        event.target.value,
                        setMissions
                      );
                    }}
                  />
                  <input
                    className="desc"
                    type="text"
                    id="passion-desc"
                    value={
                      mis.description[selectedMission.lang].passion_description
                    }
                    onChange={(event) => {
                      updateDescription(
                        mis,
                        selectedMission.lang,
                        "passion_description",
                        event.target.value,
                        setMissions
                      );
                    }}
                  />
                </div>
              </div>
              <button
                onClick={() => {
                  deleteMission(mis);
                }}
                className="delete-button"
              >
                Delete
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    }
    return _DOM;
  };
  return (
    <div className="admin-missions-container">
      <div className="sub-header">
        <div className="sub-header-content">
          <span onClick={getMissions}>Refresh</span>
          <span onClick={saveMissions}>Save</span>
          <span
            onClick={() => {
              const newMission_description: any = {};
              for (const lang of languages) {
                newMission_description[lang.code] = {
                  positivity_description: "",
                  passion_description: "",
                  people_description: "",
                  progress_description: "",
                  purpose_description: "",
                };
              }
              setnewMission({
                ...newMission,
                description: newMission_description,
              });
              setaddNewMission(true);
            }}
          >
            New Mission
          </span>
          <div className="missions-search-container">
            <input
              type="text"
              value={search}
              placeholder="Search..."
              onChange={(event) => {
                setsearch(event.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="missions-container-admin">
        {addNewMission ? (
          <div className="mission-record-container">
            <div className="mission-record">
              <input
                className="mission-record-islandname"
                value={newMission.island}
                onChange={(event) => {
                  setnewMission({
                    ...newMission,
                    island: event.target.value.toUpperCase(),
                  });
                }}
                placeholder="Island"
              />
              <input
                className="mission-record-islandname"
                value={newMission.mission}
                placeholder="Mission"
                type="number"
                onChange={(event) => {
                  setnewMission({
                    ...newMission,
                    mission: event.target.value,
                  });
                }}
              />
              <input
                className="mission-record-islandname"
                value={newMission.mission_answer}
                placeholder="Answer"
                type="number"
                onChange={(event) => {
                  setnewMission({
                    ...newMission,
                    mission_answer: event.target.value,
                  });
                }}
              />
              <button onClick={addMission}>Save</button>
              <button
                onClick={() => {
                  setaddNewMission(false);
                  setnewMission(DefMission);
                }}
              >
                X
              </button>
            </div>
            <div className="mission-extra-info-container">
              <div className="language-selector">
                {languages.map((k: any) => {
                  return (
                    <span
                      onClick={() => {
                        setnewMissionlang(k.code);
                      }}
                      className={
                        newMissionlang === k.code ? "selected-language" : ""
                      }
                    >
                      {k.code}
                    </span>
                  );
                })}
              </div>
              <div className="mission-extra-info">
                <div className="mission-col1">
                  <span>Positivity</span>
                  <span>People</span>
                  <span>Progress</span>
                  <span>Purpose</span>
                  <span>Passion</span>
                </div>
                <div className="mission-col2">
                  <input
                    className="value"
                    type="number"
                    id="positivity"
                    value={newMission.positivity}
                    onChange={(event) => {
                      setnewMission({
                        ...newMission,
                        positivity: parseInt(event.target.value),
                      });
                    }}
                  />
                  <input
                    className="value"
                    type="number"
                    id="people"
                    value={newMission.people}
                    onChange={(event) => {
                      setnewMission({
                        ...newMission,
                        people: parseInt(event.target.value),
                      });
                    }}
                  />
                  <input
                    className="value"
                    type="number"
                    id="progress"
                    value={newMission.progress}
                    onChange={(event) => {
                      setnewMission({
                        ...newMission,
                        progress: parseInt(event.target.value),
                      });
                    }}
                  />
                  <input
                    className="value"
                    type="number"
                    id="purpose"
                    value={newMission.purpose}
                    onChange={(event) => {
                      setnewMission({
                        ...newMission,
                        purpose: parseInt(event.target.value),
                      });
                    }}
                  />
                  <input
                    className="value"
                    type="number"
                    id="passion"
                    value={newMission.passion}
                    onChange={(event) => {
                      setnewMission({
                        ...newMission,
                        passion: parseInt(event.target.value),
                      });
                    }}
                  />
                </div>
                <div className="mission-col3">
                  <input
                    className="desc"
                    type="text"
                    id="positivity-desc"
                    value={
                      newMission.description[newMissionlang]
                        .positivity_description
                    }
                    onChange={(event) => {
                      setnewMission({
                        ...newMission,
                        description: {
                          ...newMission.description,
                          [newMissionlang]: {
                            ...newMission.description[newMissionlang],
                            positivity_description: event.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <input
                    className="desc"
                    type="text"
                    id="people-desc"
                    value={
                      newMission.description[newMissionlang].people_description
                    }
                    onChange={(event) => {
                      setnewMission({
                        ...newMission,
                        description: {
                          ...newMission.description,
                          [newMissionlang]: {
                            ...newMission.description[newMissionlang],
                            people_description: event.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <input
                    className="desc"
                    type="text"
                    id="progress-desc"
                    value={
                      newMission.description[newMissionlang]
                        .progress_description
                    }
                    onChange={(event) => {
                      setnewMission({
                        ...newMission,
                        description: {
                          ...newMission.description,
                          [newMissionlang]: {
                            ...newMission.description[newMissionlang],
                            progress_description: event.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <input
                    className="desc"
                    type="text"
                    id="purpose-desc"
                    value={
                      newMission.description[newMissionlang].purpose_description
                    }
                    onChange={(event) => {
                      setnewMission({
                        ...newMission,
                        description: {
                          ...newMission.description,
                          [newMissionlang]: {
                            ...newMission.description[newMissionlang],
                            purpose_description: event.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <input
                    className="desc"
                    type="text"
                    id="passion-desc"
                    value={
                      newMission.description[newMissionlang].passion_description
                    }
                    onChange={(event) => {
                      setnewMission({
                        ...newMission,
                        description: {
                          ...newMission.description,
                          [newMissionlang]: {
                            ...newMission.description[newMissionlang],
                            passion_description: event.target.value,
                          },
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {generateAccordeon()}
      </div>
    </div>
  );
};
