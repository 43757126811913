import moment from "moment";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { config } from "../../config/config";
import {
  doesSessionExist,
  getTranslations,
  isSessionOpen,
} from "../../utils/helpers";
import "./codes.style.css";

export const CodesScreen = () => {
  const session = window.location.pathname.split("/")[2];
  const [translations, settranslations] = useState<{ [key: string]: string }>(
    {}
  );
  const [sessionStatus, setsessionStatus] = useState<{
    status: string;
    start_date: Date;
  }>({ status: "", start_date: new Date() });

  useEffect(() => {
    isSessionOpen(session, setsessionStatus);
    doesSessionExist(session);
    getTranslations(settranslations, {
      session,
      keys: ["team", "session_start", "at"],
    });
  }, [session]);
  return (
    <div className="codes-container">
      {sessionStatus.status !== "active" ? (
        <div className="qr-container">
          <div className="team-container">
            <p>{translations.team} 1</p>
            <QRCode
              className="qr"
              value={`${config.base_url}/teams/1/${session}`}
            />
          </div>
          <div className="team-container">
            <p>{translations.team} 2</p>
            <QRCode
              className="qr"
              value={`${config.base_url}/teams/2/${session}`}
            />
          </div>
        </div>
      ) : (
        <div className="qr-container" style={{ color: "white", fontSize: 30 }}>
          {translations.session_start}{" "}
          {moment(sessionStatus.start_date).format("DD-MM-YY")}{" "}
          {translations.at} 7:45
        </div>
      )}
    </div>
  );
};
